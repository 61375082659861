<div
  *ngIf="isMultifamily"
  id="areas-measurements-multicolumns"
  [ngClass]="{
    'show-less': areasMeasurementsOpts.length > 10
  }"
  class="col-md-12 border-table p-0"
>
  <div
    *ngFor="let keyFact of areasMeasurementsOpts"
    [ngClass]="{ 'display-none': !keyFact?.enabled }"
    class="key-facts-multicolumns"
  >
    <div *ngIf="keyFact.enabled" class="column label-color">
      {{ keyFact.displayName }}
    </div>
    <div class="column key-facts-value label-color2">
      {{ keyFact.value }}
    </div>
  </div>
</div>
<div
  *ngIf="!isMultifamily"
  class="d-flex key-facts-columns border-table"
  id="measurement"
  [ngClass]="{
    'show-less': !hiddeMoreFeatures
  }"
>
  <div class="column label-color" *ngIf="property?.rentableArea">
    {{
      property?.buildingType.id == 2001
        ? ("detail.privateTotalAreas" | translate)
        : ("detail.totalArea" | translate)
    }}
  </div>
  <div class="column label-color2" *ngIf="property?.rentableArea">
    {{ property?.rentableArea | localNumber }}
  </div>

  <div class="column label-color" *ngIf="property?.constructionArea">
    {{ "detail.constructionArea" | translate }}
  </div>
  <div class="column label-color2" *ngIf="property?.constructionArea">
    {{ property?.constructionArea | localNumber }}
  </div>
  <div
    class="column label-color"
    *ngIf="
      property?.efficiencyRatio &&
      property?.rentableArea &&
      property?.constructionArea
    "
  >
    {{ "detail.efficiencyRatio" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      property?.efficiencyRatio &&
      property?.rentableArea &&
      property?.constructionArea
    "
  >
    {{ property?.rentableArea / property?.constructionArea | localNumber }}
  </div>
  <div class="column label-color" *ngIf="property?.floorArea">
    {{ "detail.floorArea" | translate }}
  </div>
  <div class="column label-color2" *ngIf="property?.floorArea">
    {{ property?.floorArea | localNumber }}
  </div>

  <div class="column label-color" *ngIf="property?.otherStories">
    {{ "detail.otherStories" | translate }}
  </div>
  <div class="column label-color2" *ngIf="property?.otherStories">
    {{ property?.otherStories | localNumber }}
  </div>
  <div
    *ngIf="
      property?.buildingType.id == 2001 && property?.averageFloorPrivateArea
    "
    class="column label-color"
  >
    {{ "detail.avgFloorPrivateArea" | translate }}
  </div>
  <div
    *ngIf="
      property?.buildingType.id == 2001 && property?.averageFloorPrivateArea
    "
    class="column label-color2"
  >
    {{ property?.averageFloorPrivateArea | localNumber }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.privateAvailableArea"
    class="column label-color"
  >
    {{ "detail.privateAvailableArea" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.privateAvailableArea"
    class="column label-color2"
  >
    {{ property?.privateAvailableArea | localNumber }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.bomaTotalArea"
    class="column label-color"
  >
    {{ "detail.bomaTotalArea" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.bomaTotalArea"
    class="column label-color2"
  >
    {{ property?.bomaTotalArea | localNumber }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.averageFloorBomaArea"
    class="column label-color"
  >
    {{ "detail.avgFloorBomaArea" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.averageFloorBomaArea"
    class="column label-color2"
  >
    {{ property?.averageFloorBomaArea | localNumber }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.bomaAvailableArea"
    class="column label-color"
  >
    {{ "detail.bomaAvailableArea" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 2001 && property?.bomaAvailableArea"
    class="column label-color2"
  >
    {{ property?.bomaAvailableArea | localNumber }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 2001 && property?.landArea"
    class="column label-color"
  >
    {{ "detail.landAread" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 2001 && property?.landArea"
    class="column label-color2"
  >
    {{ property?.landArea | localNumber }}
  </div>
  <div *ngIf="property?.drivingDistance" class="column label-color">
    {{ "detail.drivingDistance" | translate }}
  </div>
  <div *ngIf="property?.drivingDistance" class="column label-color2">
    {{ property?.drivingDistance | localNumber }}
  </div>
  <div
    *ngIf="
      (property?.buildingType.id == 2001 ||
        property?.buildingType.id == 9001) &&
      property?.numberStories
    "
    class="column label-color"
  >
    {{ "detail.stories" | translate }}
  </div>
  <div
    *ngIf="
      (property?.buildingType.id == 2001 ||
        property?.buildingType.id == 9001) &&
      property?.numberStories
    "
    class="column label-color2"
  >
    {{ property?.numberStories }}
  </div>
  <div *ngIf="property?.warehouse" class="column label-color">
    {{ "detail.warehouse" | translate }}
  </div>
  <div *ngIf="property?.warehouse" class="column label-color2">
    {{ property?.warehouse }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 3001 && property?.ceilingHeight"
    class="column label-color"
  >
    {{ "detail.ceilingHeight" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 3001 && property?.ceilingHeight"
    class="column label-color2"
  >
    {{ property?.ceilingHeight | localNumber }}
  </div>
  <div *ngIf="property?.tickerSymbol" class="column label-color">
    {{ "detail.tickerSymbol" | translate }}
  </div>
  <div *ngIf="property?.tickerSymbol" class="column label-color2">
    {{ property?.tickerSymbol }}
  </div>
  <div *ngIf="property?.fiiOwnershipPercentage" class="column label-color">
    {{ "detail.fiiOwnership" | translate }}
  </div>
  <div *ngIf="property?.fiiOwnershipPercentage" class="column label-color2">
    {{ property?.fiiOwnershipPercentage | localNumber }}%
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.floorLoad"
    class="column label-color"
  >
    {{ "detail.floorLoad" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.floorLoad"
    class="column label-color2"
  >
    {{ property?.floorLoad }}
  </div>
  <div *ngIf="property?.leedType" class="column label-color">
    {{ "detail.leedType" | translate }}
  </div>
  <div *ngIf="property?.leedType" class="column label-color2 leetypename">
    {{ property?.leedType.name | langSelector }}
  </div>
  <div class="featureoptionscontainer" *ngFor="let feature of featuresOptions">
    <div class="column label-color">
      {{ feature.featureDisplayName }}
    </div>
    <div class="column label-color2 feature-options">
      <span *ngFor="let option of feature.featureSelectedOptions">
        {{ option }}
      </span>
    </div>
  </div>

  <div *ngIf="property?.fireProtectionType?.name" class="column label-color">
    {{ "detail.fireProtectionType" | translate }}
  </div>
  <div *ngIf="property?.fireProtectionType?.name" class="column label-color2">
    {{ property?.fireProtectionType?.name | langSelector }}
  </div>

  <div
    *ngIf="property?.fireProtection || property?.fireProtection == false"
    class="column label-color"
  >
    {{ "detail.fireProtection" | translate }}
  </div>
  <div
    *ngIf="property?.fireProtection || property?.fireProtection == false"
    class="column label-color2"
  >
    {{
      property?.fireProtection
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.docks"
    class="column label-color"
  >
    {{ "detail.numberOfDocks" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 1001 && property?.docks"
    class="column label-color2"
  >
    {{ property?.docks }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 3001 && property?.majorTenants"
    class="column label-color"
  >
    {{ "detail.majorTenants" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id == 3001 && property?.majorTenants"
    class="column label-color2"
  >
    {{ property?.majorTenants }}
  </div>
  <div
    *ngIf="
      (property?.buildingType.id == 3001 ||
        property?.buildingType.id == 9001) &&
      property?.numberOfTenants
    "
    class="column label-color"
  >
    {{ "detail.numberOfTenants" | translate }}
  </div>
  <div
    *ngIf="
      (property?.buildingType.id == 3001 ||
        property?.buildingType.id == 9001) &&
      property?.numberOfTenants
    "
    class="column label-color2"
  >
    {{ property?.numberOfTenants }}
  </div>

  <div class="column label-color" *ngIf="property?.parkingType?.name">
    {{ "detail.parkingType" | translate }}
  </div>
  <div class="column label-color2" *ngIf="property?.parkingType?.name">
    {{ property?.parkingType?.name | langSelector }}
  </div>
  <div
    *ngIf="
      property?.buildingType.id == 2001 && property?.industrialParkingSpaces
    "
    class="column label-color"
  >
    {{ "detail.truckParkingSpaces" | translate }}
  </div>
  <div
    *ngIf="
      property?.buildingType.id == 2001 && property?.industrialParkingSpaces
    "
    class="column label-color2"
  >
    {{ property?.industrialParkingSpaces | localNumber: "1.0-0" }}
  </div>
  <div *ngIf="property?.carParkingSpaces" class="column label-color ">
    {{ "detail.carParkingSpaces" | translate }}
  </div>
  <div *ngIf="property?.carParkingSpaces" class="column label-color2">
    {{ property?.carParkingSpaces | localNumber: "1.0-0" }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 1001 && property?.parkingSpaces"
    class="column label-color"
  >
    {{ "detail.parkingSpaces" | translate }}
  </div>
  <div
    *ngIf="property?.buildingType.id != 1001 && property?.parkingSpaces"
    class="column label-color2"
  >
    {{ property?.parkingSpaces | localNumber: "1.0-0" }}
  </div>
  <div *ngIf="property?.numUnits" class="column label-color">
    {{ "detail.numUnits" | translate }}
  </div>
  <div *ngIf="property?.numUnits" class="column label-color2">
    {{ property?.numUnits }}
  </div>
  <div *ngIf="property?.rentalType" class="column label-color">
    {{ "detail.rentalType" | translate }}
  </div>
  <div *ngIf="property?.rentalType" class="column label-color2">
    {{ property?.rentalType }}
  </div>
  <div *ngIf="property?.retailStories" class="column label-color">
    {{ "detail.retailStories" | translate }}
  </div>
  <div *ngIf="property?.retailStories" class="column label-color2">
    {{ property?.retailStories }}
  </div>
  <div *ngIf="property?.apartmentStories" class="column label-color">
    {{ "detail.apartmentStories" | translate }}
  </div>
  <div *ngIf="property?.apartmentStories" class="column label-color2">
    {{ property?.apartmentStories }}
  </div>
  <div *ngIf="property?.parkingStories" class="column label-color">
    {{ "detail.parkingStories" | translate }}
  </div>
  <div *ngIf="property?.parkingStories" class="column label-color2">
    {{ property?.parkingStories }}
  </div>
  <div *ngIf="property?.furnishing?.displayName" class="column label-color">
    {{ "detail.furnishing" | translate }}
  </div>
  <div *ngIf="property?.furnishing?.displayName" class="column label-color2">
    {{ property?.furnishing?.displayName | langSelector }}
  </div>
  <div *ngIf="property?.rooms" class="column label-color">
    {{ "detail.rooms" | translate }}
  </div>
  <div *ngIf="property?.rooms" class="column label-color2">
    {{ property?.rooms }}
  </div>
  <div
    *ngIf="property?.mixedUse || property?.mixedUse == false"
    class="column label-color"
  >
    {{ "detail.mixedUse" | translate }}
  </div>
  <div
    *ngIf="property?.mixedUse || property?.mixedUse == false"
    class="column label-color2"
  >
    {{
      property?.mixedUse
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div *ngIf="property?.totalShops" class="column label-color">
    {{ "detail.totalShops" | translate }}
  </div>
  <div *ngIf="property?.totalShops" class="column label-color2">
    {{ property?.totalShops }}
  </div>
  <div *ngIf="property?.totalOccupiedShops" class="column label-color">
    {{ "detail.totalOccupiedShops" | translate }}
  </div>
  <div *ngIf="property?.totalOccupiedShops" class="column label-color2">
    {{ property?.totalOccupiedShops }}
  </div>
  <div *ngIf="property?.totalAvailableShops" class="column label-color">
    {{ "detail.totalAvailableShops" | translate }}
  </div>
  <div *ngIf="property?.totalAvailableShops" class="column label-color2">
    {{ property?.totalAvailableShops }}
  </div>
  <div
    *ngIf="property?.waterMeter || property?.waterMeter == false"
    class="column label-color"
  >
    {{ "detail.waterMeter" | translate }}
  </div>
  <div
    *ngIf="property?.waterMeter || property?.waterMeter == false"
    class="column label-color2"
  >
    {{
      property?.waterMeter
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.gasMeter || property?.gasMeter == false"
    class="column label-color"
  >
    {{ "detail.gasMeter" | translate }}
  </div>
  <div
    *ngIf="property?.gasMeter || property?.gasMeter == false"
    class="column label-color2"
  >
    {{
      property?.gasMeter
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.electricityMeter || property?.electricityMeter == false"
    class="column label-color"
  >
    {{ "detail.electricityMeter" | translate }}
  </div>
  <div
    *ngIf="property?.electricityMeter || property?.electricityMeter == false"
    class="column label-color2"
  >
    {{
      property?.electricityMeter
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.motorLobby || property?.motorLobby == false"
    class="column label-color"
  >
    {{ "detail.motorLobby" | translate }}
  </div>
  <div
    *ngIf="property?.motorLobby || property?.motorLobby == false"
    class="column label-color2"
  >
    {{
      property?.motorLobby
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.cctv || property?.cctv == false"
    class="column label-color"
  >
    {{ "detail.cctv" | translate }}
  </div>
  <div
    *ngIf="property?.cctv || property?.cctv == false"
    class="column label-color2"
  >
    {{
      property?.cctv ? ("detail.yes" | translate) : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.roofGarden || property?.roofGarden == false"
    class="column label-color"
  >
    {{ "detail.roofGarden" | translate }}
  </div>
  <div
    *ngIf="property?.roofGarden || property?.roofGarden == false"
    class="column label-color2"
  >
    {{
      property?.roofGarden
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div *ngIf="property.elevators" class="column label-color">
    {{ "detail.elevators" | translate }}
  </div>
  <div *ngIf="property.elevators" class="column label-color2">
    {{ property.elevators }}
  </div>
  <div
    *ngIf="property?.managerOnSite || property?.managerOnSite == false"
    class="column label-color"
  >
    {{ "detail.managerOnSite" | translate }}
  </div>
  <div
    *ngIf="property?.managerOnSite || property?.managerOnSite == false"
    class="column label-color2"
  >
    {{
      property?.managerOnSite
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.hasElevators || property?.hasElevators == false"
    class="column label-color hasElevators"
  >
    {{ "detail.elevators" | translate }}
  </div>
  <div
    *ngIf="property?.hasElevators || property?.hasElevators == false"
    class="column label-color2 hasElevators"
  >
    {{
      property?.hasElevators
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.airConditioning || property?.airConditioning == false"
    class="column label-color airConditioning"
  >
    {{ "detail.airConditioning" | translate }}
  </div>
  <div
    *ngIf="property?.airConditioning || property?.airConditioning == false"
    class="column label-color2 airConditioning"
  >
    {{
      property?.airConditioning
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.fitnessCenter || property?.fitnessCenter == false"
    class="column label-color fitnessCenter"
  >
    {{ "detail.FitnessCenter" | translate }}
  </div>
  <div
    *ngIf="property?.fitnessCenter || property?.fitnessCenter == false"
    class="column label-color2 fitnessCenter"
  >
    {{
      property?.fitnessCenter
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.clubhouse || property?.clubhouse == false"
    class="column label-color"
  >
    {{ "detail.Clubhouse" | translate }}
  </div>
  <div
    *ngIf="property?.clubhouse || property?.clubhouse == false"
    class="column label-color2"
  >
    {{
      property?.clubhouse
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.wifiInCommonAreas || property?.wifiInCommonAreas == false"
    class="column label-color"
  >
    {{ "detail.wiFiInCommonAreas" | translate }}
  </div>
  <div
    *ngIf="property?.wifiInCommonAreas || property?.wifiInCommonAreas == false"
    class="column label-color2"
  >
    {{
      property?.wifiInCommonAreas
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="
      property?.onlineResidentServices ||
      property?.onlineResidentServices == false
    "
    class="column label-color"
  >
    {{ "detail.onlineResidentServices" | translate }}
  </div>
  <div
    *ngIf="
      property?.onlineResidentServices ||
      property?.onlineResidentServices == false
    "
    class="column label-color2"
  >
    {{
      property?.retailOnSite
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.playground || property?.playground == false"
    class="column label-color playground"
  >
    {{ "detail.playground" | translate }}
  </div>
  <div
    *ngIf="property?.playground || property?.playground == false"
    class="column label-color2 playground"
  >
    {{
      property?.playground
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.dogWalker || property?.dogWalker == false"
    class="column label-color"
  >
    {{ "detail.dogWalker" | translate }}
  </div>
  <div
    *ngIf="property?.dogWalker || property?.dogWalker == false"
    class="column label-color2"
  >
    {{
      property?.dogWalker
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.sharedKitchen || property?.sharedKitchen == false"
    class="column label-color"
  >
    {{ "detail.sharedKitchen" | translate }}
  </div>
  <div
    *ngIf="property?.sharedKitchen || property?.sharedKitchen == false"
    class="column label-color2"
  >
    {{
      property?.sharedKitchen
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.sharedLaundry || property?.sharedLaundry == false"
    class="column label-color"
  >
    {{ "detail.sharedLaundry" | translate }}
  </div>
  <div
    *ngIf="property?.sharedLaundry || property?.sharedLaundry == false"
    class="column label-color2"
  >
    {{
      property?.sharedLaundry
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.partyRoom || property?.partyRoom == false"
    class="column label-color"
  >
    {{ "detail.partyRoom" | translate }}
  </div>
  <div
    *ngIf="property?.partyRoom || property?.partyRoom == false"
    class="column label-color2"
  >
    {{
      property?.partyRoom
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.dishwasher || property?.dishwasher == false"
    class="column label-color"
  >
    {{ "detail.dishwasher" | translate }}
  </div>
  <div
    *ngIf="property?.dishwasher || property?.dishwasher == false"
    class="column label-color2"
  >
    {{
      property?.dishwasher
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.disposal || property?.disposal == false"
    class="column label-color"
  >
    {{ "detail.disposal" | translate }}
  </div>
  <div
    *ngIf="property?.disposal || property?.disposal == false"
    class="column label-color2"
  >
    {{
      property?.disposal
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.washerDryerHookup || property?.washerDryerHookup == false"
    class="column label-color"
  >
    {{ "detail.WasherDryerHookup" | translate }}
  </div>
  <div
    *ngIf="property?.washerDryerHookup || property?.washerDryerHookup == false"
    class="column label-color2"
  >
    {{
      property?.washerDryerHookup
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.walkInClosets || property?.walkInClosets == false"
    class="column label-color"
  >
    {{ "detail.WalkInClosets" | translate }}
  </div>
  <div
    *ngIf="property?.walkInClosets || property?.walkInClosets == false"
    class="column label-color2"
  >
    {{
      property?.walkInClosets
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="
      property?.graniteCountertops || property?.graniteCountertops == false
    "
    class="column label-color"
  >
    {{ "detail.graniteCountertops" | translate }}
  </div>
  <div
    *ngIf="
      property?.graniteCountertops || property?.graniteCountertops == false
    "
    class="column label-color2"
  >
    {{
      property?.graniteCountertops
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="
      property?.highSpeedInternetAccess ||
      property?.highSpeedInternetAccess == false
    "
    class="column label-color"
  >
    {{ "detail.highSpeedInternetAccess" | translate }}
  </div>
  <div
    *ngIf="
      property?.highSpeedInternetAccess ||
      property?.highSpeedInternetAccess == false
    "
    class="column label-color2"
  >
    {{
      property?.highSpeedInternetAccess
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.wifi || property?.wifi == false"
    class="column label-color"
  >
    {{ "detail.WiFi" | translate }}
  </div>
  <div
    *ngIf="property?.wifi || property?.wifi == false"
    class="column label-color2"
  >
    {{
      property?.wifi ? ("detail.yes" | translate) : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.hardwoodFloors || property?.hardwoodFloors == false"
    class="column label-color"
  >
    {{ "detail.hardwoodFloors" | translate }}
  </div>
  <div
    *ngIf="property?.hardwoodFloors || property?.wifhardwoodFloorsi == false"
    class="column label-color2"
  >
    {{
      property?.hardwoodFloors
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="
      property?.stainlessSteelAppliances ||
      property?.stainlessSteelAppliances == false
    "
    class="column label-color"
  >
    {{ "detail.stainlessSteelAppliances" | translate }}
  </div>
  <div
    *ngIf="
      property?.stainlessSteelAppliances ||
      property?.stainlessSteelAppliances == false
    "
    class="column label-color2"
  >
    {{
      property?.stainlessSteelAppliances
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.ceilingFans || property?.ceilingFans == false"
    class="column label-color ceilingFans"
  >
    {{ "detail.ceilingFans" | translate }}
  </div>
  <div
    *ngIf="property?.ceilingFans || property?.ceilingFans == false"
    class="column label-color2 ceilingFans"
  >
    {{
      property?.ceilingFans
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.privatePatio || property?.privatePatio == false"
    class="column label-color"
  >
    {{ "detail.privatePatio" | translate }}
  </div>
  <div
    *ngIf="property?.privatePatio || property?.privatePatio == false"
    class="column label-color2"
  >
    {{
      property?.privatePatio
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.cleaningServices || property?.cleaningServices == false"
    class="column label-color"
  >
    {{ "detail.cleaningServices" | translate }}
  </div>
  <div
    *ngIf="property?.cleaningServices || property?.cleaningServices == false"
    class="column label-color2"
  >
    {{
      property?.cleaningServices
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.petFriendly || property?.petFriendly == false"
    class="column label-color petFriendly"
  >
    {{ "detail.petFriendly" | translate }}
  </div>
  <div
    *ngIf="property?.petFriendly || property?.petFriendly == false"
    class="column label-color2 petFriendly"
  >
    {{
      property?.petFriendly
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div
    *ngIf="property?.blockConstruction || property?.blockConstruction == false"
    class="column label-color "
  >
    {{ "detail.blockConstruction" | translate }}
  </div>
  <div
    *ngIf="property?.blockConstruction || property?.blockConstruction == false"
    class="column label-color2"
  >
    {{
      property?.blockConstruction
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="property?.steelConstruction || property?.steelConstruction == false"
    class="column label-color "
  >
    {{ "detail.steelConstruction" | translate }}
  </div>
  <div
    *ngIf="property?.steelConstruction || property?.steelConstruction == false"
    class="column label-color2"
  >
    {{
      property?.steelConstruction
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>
  <div
    *ngIf="
      property?.blockAndSteelConstruction ||
      property?.blockAndSteelConstruction == false
    "
    class="column label-color "
  >
    {{ "detail.blockAndSteelConstruction" | translate }}
  </div>
  <div
    *ngIf="
      property?.blockAndSteelConstruction ||
      property?.blockAndSteelConstruction == false
    "
    class="column label-color2"
  >
    {{
      property?.blockAndSteelConstruction
        ? ("detail.yes" | translate)
        : ("detail.no" | translate)
    }}
  </div>

  <div *ngIf="property?.industrialParkingSpaces" class="column label-color ">
    {{ "detail.industrialParkingSpaces" | translate }}
  </div>
  <div *ngIf="property?.industrialParkingSpaces" class="column label-color2">
    {{ property?.industrialParkingSpaces | localNumber: "1.0-0" }}
  </div>
  <div *ngIf="property?.baySize" class="column label-color ">
    {{ "detail.baySize" | translate }}
  </div>
  <div *ngIf="property?.baySize" class="column label-color2">
    {{ property?.baySize }}
  </div>
  <div *ngIf="property?.dockRatio" class="column label-color ">
    {{ "detail.dockRatio" | translate }}
  </div>
  <div *ngIf="property?.dockRatio" class="column label-color2">
    {{ property?.dockRatio }}
  </div>
</div>

import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-areas-measurements-mx",
  templateUrl: "./areas-measurements-mx.component.html",
  styleUrls: ["./areas-measurements-mx.component.scss"]
})
export class AreasMeasurementsMxComponent implements OnInit {
  @Input() property: any;
  @Input() isMultifamily;
  @Input() areasMeasurementsOpts = [];
  @Input() complexInformation;
  typeOpt = 1;
  constructor() {}

  ngOnInit(): void {}
}

export const environment = {
  production: true,
  baseHref: "/",
  apiURL: "/api",
  apiURLMX: "/api/mx-be",
  apiURLBR: "/api/br-be",
  platformId: "br",
  applicationURL: "https://stg.siila.com.br",
  applicationURLBr: "https://stg.siila.com.br",
  applicationURLMx: "https://siila.com.mx",
  googleTrackingID: "G-LWZMDNZRQE",
  ws: "wss://44i6e0stm6.execute-api.sa-east-1.amazonaws.com/stg",
  serverURL: "https://stgapp.siila.com.br",
  redirectURL: "https://stgapp.siila.com.br/#!/",
  spotURL: "https://stg.siilaspot.com.br",
  spotURLMx: "https://stg.siilaspot.com.mx",
  listingDetailURL: "https://stgapp.siila.com.br/#!/listing/",
  cloudfrontURL: "https://dnclcgcvl4f14.cloudfront.net/",
  googleAPIKey: "AIzaSyD7ILit-WpiTauIV0bdY2OpeSGnNfA9jXE"
};

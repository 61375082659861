import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-areas-measurements-br",
  templateUrl: "./areas-measurements-br.component.html",
  styleUrls: ["./areas-measurements-br.component.scss"]
})
export class AreasMeasurementsBrComponent implements OnInit {
  @Input() property: any;
  @Input() isMultifamily;
  @Input() areasMeasurementsOpts = [];
  @Input() hiddeMoreFeatures;
  constructor() {}

  ngOnInit(): void {}
}
